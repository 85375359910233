@import '../../_variables.scss';

.container {
  display: flex;
  align-items: center;

  .avatar {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-top: 1rem;
    .avatarImage {
      width: 200px;
      height: 300px;
      background: lightgray;
      background-image: url('https://i.imgur.com/F3b2OUc.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .avatarColor {
      width: 150px;
      height: 200px;
      background: $yellowColor;
    }
  }
  .name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    position: relative;
    a {
      text-decoration: none;
      color: rgb(0, 141, 216);
    }
    h1 {
      font-size: 64px;
      font-weight: 700;
      color: #28262c;
      margin-bottom: 2rem;
      margin-top: 1rem;
      line-height: 76.8px;
      letter-spacing: 7px;
    }
    h4 {
      font-size: 24px;
      font-weight: 400;
      color: #28262c;
      margin: 0 auto;
      font-family: 'Merriweather', serif;
    }
    hr {
      position: absolute;
      height: 25px;
      border: none;
      background-color: $yellowColor;
      width: 800px;
      top: 60px;
      opacity: 1;
      transition: all 0.3s ease;
      z-index: -1;
      transform: translateX(-0.7%);
    }
    .skills {
      display: flex;
      justify-content: center;
      align-items: center;
      .fab {
        font-size: 32px;
        margin: 1rem;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .container {
    flex-direction: column;
    margin: 1 auto !important;
  }
  .name {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: center;
    padding: 1rem !important;
    h1 {
      font-size: 32px !important;
      font-weight: 700;
      color: #28262c;
      margin-bottom: 1rem !important;
      margin-top: 0rem !important;
      line-height: 76.8px;
      letter-spacing: 7px;
    }
    hr {
      position: absolute;
      height: 14px !important;
      border: none;
      background-color: $yellowColor;
      width: 460px !important;
      top: 479px !important;
      opacity: 1;
      transition: all 0.3s ease;
      z-index: -1;
      transform: translateX(-0.7%);
    }
    .jobTitle {
      display: none !important;
    }
  }
}
@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem !important;
  }
  .avatarImage {
    width: 150px !important;
    height: 200px !important;
    background: lightgray;
    background-image: url('https://scontent.fotp3-3.fna.fbcdn.net/v/t31.18172-8/1398408_10200909696484816_1774043895_o.jpg?_nc_cat=102&ccb=1-5&_nc_sid=174925&_nc_ohc=Tjw2lm1h980AX_1jcsd&_nc_ht=scontent.fotp3-3.fna&oh=da52df738da0f9bf7782555caa636bd0&oe=61896F68');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .avatarColor {
    width: 75px !important;
    height: 150px !important;
    background: $yellowColor;
  }
  .name {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: center;
    padding: 1rem !important;
    text-align: center;
    position: relative;
    h1 {
      font-size: 28px !important;
      font-weight: 700;
      color: #28262c;
      margin-bottom: 1rem !important;
      margin-top: 2rem !important;
      line-height: 32.8px !important;
      letter-spacing: 4px;
    }
    hr {
      position: absolute;
      height: 14px !important;
      border: none;
      background-color: $yellowColor;
      width: 360px !important;
      top: 179px !important;
      opacity: 1;
      transition: all 0.3s ease;
      z-index: -1;
      transform: translateX(-0.7%);
    }
  }
  .skills {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .fab {
      font-size: 24px !important;
      margin: 1rem;
    }
  }
}
