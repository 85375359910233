@import '../../variables';

.container {
  margin-top: 3rem;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
    color: black;
  }
}

.button {
  max-height: 50px;
  cursor: pointer;
  .buttonLogo {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .buttonMessage {
    opacity: 0.2;
    transition: visibility 0s, opacity 0.5s linear;
    transition: all 0.3s ease-in-out;
  }
  img {
    height: 30px;
    margin-left: 1rem;
  }
}

.button:hover {
  .buttonMessage {
    opacity: 1;
  }
}

@media screen and (max-width: 1400px) {
  section {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
  }
}
