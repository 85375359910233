@import '../../variables';

.container {
  display: flex;
  margin-bottom: 3rem;
  width: 90%;
  .containerDescription {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 1rem;

    .description {
      font-family: 'Roboto', sans-serif;
      margin-bottom: 1rem;
      width: 80%;
      color: gray;
      line-height: 150%;
      span {
        font-weight: 700;
        text-decoration: underline;
      }
    }
    .private {
      color: red;
    }
    button {
      width: 150px;
      height: 50px;
      cursor: pointer;
      background: white;
      transition: all 0.3s ease;
      border: 1px solid black;
      margin-right: 1rem;
      &:hover {
        background: $yellowColor;
        transition: all 0.3s ease;
      }
    }
  }
  .name {
    font-family: 'Roboto', sans-serif;
    letter-spacing: 3px;
    font-size: 32px;
    margin-bottom: 1rem;
    a {
      text-decoration: none;
      color: rgb(0, 141, 216);
      &:hover {
        text-decoration: underline;
        transition: all 0.3s ease;
      }
    }
  }
  .thumbnail {
    position: relative;

    .skills {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 300px;
      height: 200px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
        0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11), 0 8px 16px rgba(0, 0, 0, 0.11);
    }
    .thumbnailBox {
      width: 300px;
      height: 200px;
      background: $yellowColor;
      position: absolute;
      top: -25px;
      left: 25px;
      z-index: -1;
    }
  }

  @media screen and (max-width: 1400px) {
    .container {
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgb(249, 249, 249);
    border-radius: 30px;
    padding: 2rem;
    box-shadow: 0px 5px 10px 0px rgba(4, 4, 4, 0.146);
    .containerDescription {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0.3rem !important;
      .read-more {
        margin-bottom: 1rem;
        a {
          color: black;
          &:hover {
            color: rgb(83, 83, 51);
          }
        }
      }
      .description {
        font-family: 'Merriweather', serif;
        margin-bottom: 1rem;
        word-spacing: 3px;
        letter-spacing: 2px;
      }
    }
  }
  .thumbnail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .skills {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .skills {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 200px !important;
  }
  button {
    border: none;
    width: 100% !important;
    height: 30px;
    border-radius: 10px;
    cursor: pointer;
    background: $yellowColor;
    transition: all 0.3s ease;
    &:hover {
      background: $yellowColor;
      transition: all 0.3s ease;
    }
  }
}

.projectContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
}
.hero {
  display: flex;

  justify-content: space-between;
  width: 100%;
  gap: 2rem;
  img {
    max-width: 800px;
    height: 100%;
    object-fit: cover;
  }
}

.title {
  font-size: 1.5rem;
  gap: 2rem;
  width: 100%;
}

.projectTech {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
  h3 {
    font-size: 1.2rem;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.1rem;
  }
  .projectSkills {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    span {
      font-weight: bold;
    }
  }
}

.featureImage {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  img {
    max-width: 600px;
    height: 400px;
    border: 1px solid rgb(158, 158, 158);
    border-radius: 10px;
    object-fit: cover;
    cursor: pointer;
  }
}
