.h1 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
}
.h2 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}
.h3 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
}
.h4 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
}
.h5 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2;
}
.h6 {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.2;
}
.p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.txt {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.1rem;
  margin-bottom: 5rem;
}
