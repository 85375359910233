.container {
  display: flex;
  justify-content: flex-end;
  margin: 5rem;
}

.indent {
  width: 26%;
  hr {
    height: 26px;
    border: none;
    float: right;
    background-color: #f9ea9a;
    width: 70px;
    margin-right: 30px;
    margin-top: 8px;
  }
}

.description {
  width: 85%;
  font-family: 'Merriweather', serif;
  font-weight: 300;
  line-height: 200%;
  letter-spacing: 0.1em;
  p {
    margin-bottom: 1rem;
  }
  h4 {
    font-size: 20px;
    margin-bottom: 1rem;
  }
  li {
    margin-bottom: 1rem;
    list-style-type: square;
  }
}

@media screen and (max-width: 1400px) {
  hr {
    display: none;
  }
  .container {
    display: block;
    margin: 1 auto;
    .description {
      width: 100%;
      font-family: 'Merriweather', serif;
      font-weight: 300;
      line-height: 200%;
      letter-spacing: 0.1em;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    display: block;
    margin: 1rem auto;
    padding: 2rem;
  }
}
