@import '../../variables';

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 4rem;

  p {
    width: 80%;
  }
  .links {
    font-size: 24px;
    margin: 1rem;
  }
  hr {
    height: 24px;
    border: none;
    float: right;
    background-color: $yellowColor;
    width: 50px;
    margin-right: 30px;
  }
}

@media screen and (max-width: 1400px) {
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 1rem auto;
    p {
      width: 20%;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 0rem;

    p {
      width: 100%;
      text-align: center;
    }
    .links {
      font-size: 24px;
      margin: 0rem;
    }
  }
}
