@import './variables';
body {
  font-family: 'Lato', sans-serif;
  max-width: 1440px;
  margin: 0 auto;
}

.fas {
  color: black;
  &:hover {
    color: #ffc107;
  }
}

.fab {
  margin: 1rem;
  font-size: 24px;
  cursor: pointer;
  color: black;
  &:hover {
    opacity: 0.5;
  }
}

@media screen and (max-width: 420px) {
  #root {
    max-width: 390px;
  }
}

@media screen and (max-width: 728px) and (min-width: 420px) {
  #root {
    max-width: 728px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 728px) {
  #root {
    max-width: 1024px;
  }
}
