@import '../../variables';

.navbar {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  list-style: none;
  margin-right: 5rem;
  a {
    text-decoration: none;
    color: $fontColor;
  }
  li {
    padding: 1rem;
  }
  .link {
    font-size: 24px;
    position: relative;
    hr {
      position: absolute;
      height: 10px;
      border: none;
      background-color: #f9ea9a;
      width: 4rem;
      top: 2.3rem;
      opacity: 0;
      transition: all 0.3s ease;
      transform: translateX(-100%);
    }
    &:hover {
      hr {
        opacity: 1;
        transition: all 0.3s ease;
        transform: translateX(0);
      }
    }
    .inactiveLocation {
      opacity: 0;
      transform: translateX(-100%);
      transition: all 0.3s ease;
    }

    .activeLocation {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .resume {
    font-size: 24px;
    transition: all 0.6s ease;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      transition: all 0.6s ease;
      background: #f9ea9a;
      &::after {
        opacity: 1;
        transition: all 0.6s ease;
      }
    }
    &::after {
      content: 'Download Resume';
      font-size: 12px;
      position: absolute;
      margin-top: 7rem;
      color: gray;
      width: 50px;
      text-align: center;
      opacity: 0;
      transition: all 0.6s ease;
      pointer-events: none;
    }
  }
}
.hamburgerNav {
  display: none;
}

.hamburgerLinks {
  display: none;
}

.hamburgerHeader {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 0 auto;
  width: 100%;
}

.hamburgerNavTitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
}

@media screen and (max-width: 728px) {
  .navbar {
    display: none;
  }

  .hamburgerHeader {
    display: flex;
  }

  .hamburgerNav {
    display: flex;
    font-size: 36px;
    justify-content: flex-end;
    padding: 1rem;
    cursor: pointer;
    z-index: 20;
    position: relative;
    width: 100%;
  }

  .hamburgerLinks {
    position: absolute;
    display: none;
    background: white;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    transform: translateX(100%);
    transition: all 0.3s ease;
    z-index: 10;
    margin: 0 auto;
    padding: 10px;
    li {
      list-style-type: none;
      padding: 1rem;
      border-bottom: 1px solid #e1e1e1;
      width: 100%;
      cursor: pointer;
      &:hover {
        background: #fdfbf0;
      }
    }
    a {
      text-decoration: none;
      color: $fontColor;
      font-size: 24px;
    }
    .resume {
      display: flex;
      gap: 10px;
    }
  }

  .showHamburger {
    transform: translateY(0%);
    transition: all 0.3s ease;
    display: flex;
  }
}
